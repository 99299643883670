
import DisplayStory from "@evercam/shared/components/DisplayStory"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
export default {
  components: {
    DisplayStory,
  },
  meta: {
    public: true,
    pageId: AnalyticsEventPageId.Tutorials,
  },
  head() {
    return {
      title: `Evercam | ${this.$t("pages.tutorials")}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    localStorage.setItem("tutorials-access", 1)
  },
}
